// import React, { useState, useEffect } from 'react';
// // import Slider, { Range } from 'rc-slider';
// // import 'rc-slider/assets/index.css';

// export const RangeSlider = ({ min, max }) => {
//     let [val, setVal] = useState(min / 2)
//     useEffect(() => {
//         let data = val.toString()
//         let vallll = data + "%"
//         document.documentElement.style.setProperty('--webkitProgressPercent', vallll);
//         const color = getComputedStyle(document.documentElement).getPropertyValue('--webkitProgressPercent');
//         console.log(color);
//     }, [val]);
//     return (
//         <div classNameName="aptooltip">
//             {/* <span classNameName="aptooltiptext">Tooltip text:{val} </span> */}

//             <input type="range" name="foo" onChange={(e) => { setVal(e.target.value) }} min="1" max="100" classNameName='slider' />

//         </div>
//     )
// }


import * as React from "react";
import "./rangeSlider.css"

export  function RangeSlider({ max, value, onChange }) {
  if (!value) {
    value = 0;
  }
//   const [val, setVal] = React.useState(value);
  const range = React.useRef(null);
  const tooltip = React.useRef(null);

  const getPercent = React.useCallback(
    (value) => Math.round(((value - 0) / (max - 0)) * 100),
    [max]
  );

  // Set width of the range to decrease from the left side
  React.useEffect(() => {
    const minPercent = getPercent(value);

    if (range.current) {
      range.current.style.right = `${100 - minPercent}%`;
      range.current.style.width = `${minPercent}%`;
    }

    if(tooltip.current) {
        tooltip.current.style.left = `${minPercent}%`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <div className="slider relative w-full">
        <div className="slider__track absolute h-px w-full bg-gray-300"></div>
        <div ref={tooltip} className="slider__left-value absolute left-2">{value}</div>

        <div
          ref={range}
          className="slider__range absolute bg-gray-500 h-px"
        ></div>
      </div>
      <input
        type="range"
        name=""
        min={0}
        max={max}
        value={value}
        onChange={onChange}
        id=""
        className="thumb thumb--left appearance-none h-0 w-full relative bottom-5 z-40 pointer-events-none"
      />
    </>
  );
}


