
import "./App.css"
import { Main } from "./Components/main/Main";

function App() {
 



  
  return (
    <div className="mainApp">
      <Main/>
    
    </div>
  );
}

export default App;

