import React,{memo} from 'react'
import "./AnswerText.css"
export const AnswersText =memo(({AnswersText,ans}) => {
  return (
    <div className='answerMain'>
        <div className='anscol1'> {AnswersText}</div>
        <div className='anscol2'>${ans}</div>
    </div>
  )
} )
