import React, { memo } from 'react'
import { AnswersText } from '../Answer/AnswersText'
import { BlackandGreen } from '../blackAndGreenheading/blackandGreen'
import { BlackandGreenTax } from '../blackAndGreenheading/blackAndGreenTax'
import "./calculationSummary.css"
export const CalculationSummary = memo( ({sessionHour,workweek,AnnualExpense,AnnualIncome,taxGrossIncome}) => {
    return (
        <div className='calMain'>
            <div className='col1cal'>
                <div className='caltext'>
                Here’s a birdseye view to give you the Clarity
                you need to reach your
                dream lifestyle
                </div>
               
            </div>
            <div>
                <AnswersText AnswersText="Desired Take-Home Income" ans={AnnualIncome}/>
                <AnswersText AnswersText="Pre-Tax Income" ans={taxGrossIncome}/>
                <AnswersText AnswersText="Annual Business Expenses" ans={AnnualExpense}/>
                
                <AnswersText AnswersText="of Working Weeks Per Year" ans={workweek}/>
                <AnswersText AnswersText="of Patient Hours Per Week" ans={sessionHour}/>
                <BlackandGreenTax value={Math.round(((parseInt(taxGrossIncome)+parseInt(AnnualExpense))/workweek)/sessionHour)} text="Therapy Fee" tooltipText="Therapy Fee"/>

                


            </div>

        </div>
    )
}
)