export const ColoredLine = () => (
    <hr
    style={{
        color: "#C4C4C4",
        backgroundColor:"#C4C4C4",
        width:"80%",
        height: "2px",
        marginBottom:"5%",
        marginTop:"5%"

    }}
    />
);