import React, { memo } from "react";
import { Input } from "../input/Input";
import { BoldParaAndToolTip } from "../boldParaAndToolTip/BoldParaAndToolTip";
import "./bParaTTipAndIp.css";
export const BoldParaToolTipAndInput = memo(
  ({
    boldParaText,
    value,
    tooltipText,
    inputName,
    inputOnchange,
    read,
    unit = "",
  }) => {
    return (
      <div className="boldParaToolTipAndInput_container">
        <div className="bpttc_row1">
          <BoldParaAndToolTip
            boldParaText={boldParaText}
            tooltipText={tooltipText}
          />
        </div>
        <div className="bpttc_row2">
          <Input
            read={read}
            value={value}
            onchange={inputOnchange}
            name={inputName}
            unit={unit}
          />
        </div>
      </div>
    );
  }
);
