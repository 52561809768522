import React, { memo } from "react";
import { BoldParaAndToolTip } from "../boldParaAndToolTip/BoldParaAndToolTip";
import { InputRange } from "../InputAndRange/InputAndRange";
import "./boldParaTooltipIRange.css";
export const BoldParaTTipIRange = memo(
  ({
    boldParaText,
    tooltipText,
    currentValue,
    max,
    onchange,
    unit = "",
    pre = true,
  }) => {
    return (
      <div className="BoldParaTTipIRange_main">
        <div className="child1">
          <BoldParaAndToolTip
            boldParaText={boldParaText}
            tooltipText={tooltipText}
          />
        </div>
        <div className="InputRange">
          <InputRange
            value={currentValue}
            maxvalue={max}
            onchange={onchange}
            unit={unit}
            pre={pre}
          />
        </div>
      </div>
    );
  }
);
