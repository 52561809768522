import React from "react";
import "./input.css";

export const Input = ({
  name,
  onchange,
  value,
  read,
  unit = "",
  pre = true,
}) => {
  return (
    <div className="inputField">
      <input
        className="input_input"
        readOnly={read}
        onChange={onchange}
        value={value}
        required
        name={name}
        type="number"
      />
      <span className={pre ? "preUnit" : "postUnit"}>{unit}</span>
    </div>
  );
};
