import React, { memo } from 'react'
import "./blackAndGreen.css"
import { Tooltip } from '../tooltip/Tooltip'
export const BlackandGreenTax = memo(({text,tooltipText,value}) => {

 return (
    <div className='BlackandGreen_container'>
        <div className='child1'>{text}<Tooltip Tooltiptext={tooltipText}/></div>
        <div className='child2'>${value?value:0}</div>
    </div>
  )
})
