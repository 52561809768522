import React, { useState } from "react";
import { RangeSlider } from "../rangeSlider/RangeSlider";
import { Input } from "../input/Input";
import "./inputAndRange.css";
export const InputRange = ({
  onchange,
  value,
  maxvalue,
  unit = "",
  pre = true,
}) => {
  return (
    <div className="InputRange_Container">
      <div className="inputRange_child1">
        <RangeSlider max={maxvalue} value={value} onChange={onchange} />
      </div>
      <div className="inputRange_child2">
        <Input value={value} onchange={onchange} unit={unit} pre={pre} />
      </div>
    </div>
  );
};
