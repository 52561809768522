import React, { memo } from 'react'
import "./blackAndGreen.css"
import { Tooltip } from '../tooltip/Tooltip'
export const BlackandGreen = memo(({text,tooltipText,proBoneReducedFee,superTeaching,rentalIncome,leaderShip,mics }) => {

 return (
    <div className='BlackandGreen_container'>
        <div className='child1'>{text}<Tooltip Tooltiptext={tooltipText}/></div>
        <div className='child2'>${(  parseInt(proBoneReducedFee) +parseInt(superTeaching)+parseInt(rentalIncome)+parseInt(leaderShip)+parseInt(mics))*12}</div>
    </div>
  )
})
