import React, { useState } from "react";
import { BoldParaTTipIRange } from "../boldPara-toolTip-Range-Input/boldPara-toolTip-Range-Input";
import { BoldParaToolTipAndInput } from "../boldPara-TootTip-input/boldParaToolTipAndInput";
import { Heading } from "../heading/Heading";
import { Paragraph } from "../paragraph/Paragraph";
import { BsCheck2Circle } from "react-icons/bs";
import "./main.css";
import { BlackandGreen } from "../blackAndGreenheading/blackandGreen";
import { BlackandGreenTax } from "../blackAndGreenheading/blackAndGreenTax";
import { ColoredLine } from "../line/Line";
import { CalculationSummary } from "../calculation summary/CalculationSummary";
import CalculatorHeader from "../calculatorHeader";
export const Main = () => {
  let [AnnualIncome, setAnnualIncome] = useState(0);
  let [vacationWeek, setvacationWeek] = useState(0);
  let [WeekSessions, setweekSessions] = useState(0);
  let [AverageFee, setAverageFee] = useState(0);
  let [reduceFee, setreduceFee] = useState(0);
  let [AvgSlidingFee, setAvgSlidingFee] = useState(0);
  let [AttendanceRate, setAttendanceRate] = useState(0);
  let [proBoneReducedFee] = useState(
    (parseInt(reduceFee) *
      parseInt(AvgSlidingFee) *
      (52 - parseInt(vacationWeek))) /
      12
  );
  let [superTeaching, setSuperTeaching] = useState(0);
  let [rentalIncome, setRentalIncome] = useState(0);
  let [leaderShip, setleaderShip] = useState(0);
  let [mics, setMics] = useState(0);
  let [tax, setTax] = useState(0);
  let [estate, setEstate] = useState(0);
  let [monthlyExpences, setMonthlyExpences] = useState(0);

  return (
    <>
      <CalculatorHeader
        title="CHARGE WHAT YOU’RE WORTH"
        subtitle="Session Fee Calculator"
        text="Find a rate that reflects your worth and helps you live your dream life."
      />
      <div className="main">
        <div className="main_row1">
          {console.log(AnnualIncome)}
          {console.log(vacationWeek)}

          <Heading text="Desired Lifestyle" />
          <Paragraph text="Let us know what lifestyle you envision and we’ll provide an aspirational analysis to help youscale your practice." />
          <BoldParaToolTipAndInput
            inputName="anualIncome"
            unit="$"
            inputOnchange={(e) => {
              setAnnualIncome(e.target.value);
            }}
            boldParaText="My annual income goal (after taxes and business expenses)"
            tooltipText="Text not provided for this tooltip"
          />
          <BoldParaTTipIRange
            currentValue={vacationWeek}
            max={16}
            unit="weeks"
            pre={false}
            onchange={(e) => {
              if (e.target.value > 16) {
                return;
              }
              setvacationWeek(e.target.value);
            }}
            boldParaText="How many weeks of vacation or time off for would you like each year?"
            tooltipText="Between cancellations, holidays, and vacations, therapists generally work 42 weeks out of the year which equates to 10 weeks of vacation."
          />
          <BoldParaTTipIRange
            currentValue={WeekSessions}
            max={25}
            onchange={(e) => {
              if (e.target.value > 25) {
                return;
              }
              setweekSessions(e.target.value);
            }}
            boldParaText="How many sessions would you like to conduct each week?"
            tooltipText="Most therapists prefer about 20-25 patient hours a
            week."
          />
          <BoldParaTTipIRange
            currentValue={AverageFee}
            max={1000}
            unit="$"
            onchange={(e) => {
              if (e.target.value > 1000) {
                return;
              }
              setAverageFee(e.target.value);
            }}
            boldParaText="What is the average session fee for your discipline in your area?"
            tooltipText="If you’re unsure about average rates, consult with
            colleagues or use the Fair Health Consumer Calculator for 90834 or 90837 CPT codes"
          />
          <BoldParaTTipIRange
            currentValue={reduceFee}
            max={10}
            onchange={(e) => {
              if (e.target.value > 10) {
                return;
              }
              setreduceFee(e.target.value);
            }}
            boldParaText="How many pro-bono and sliding scale sessions would you like to offer each week?"
            tooltipText="Many therapists find fulfillment in offering a select
            number of clients a reduced rate so that they can have access to long-term, affordable
            care."
          />
          <BoldParaTTipIRange
            currentValue={AvgSlidingFee}
            max={500}
            unit="$"
            onchange={(e) => {
              if (e.target.value > 500) {
                return;
              }
              setAvgSlidingFee(e.target.value);
            }}
            boldParaText="What’s the average pro-bono and sliding scale fee you to offer each week?"
            tooltipText=""
          />
          <BoldParaTTipIRange
            currentValue={AttendanceRate}
            max={100}
            unit="%"
            onchange={(e) => {
              if (e.target.value > 100) {
                return;
              }
              setAttendanceRate(e.target.value);
            }}
            boldParaText="What’s your average weekly attendance rate?"
            tooltipText=""
          />
        </div>
        <div className="main_row2">
          <div className="main_row2_c1">
            <Heading text="Other Income" />
            <Paragraph text="Other Income Let us know additional sources of diversified revenue streams so that we can get you closer to your ideal financial goals." />
            <BoldParaToolTipAndInput
              read={true}
              unit="$"
              value={
                (parseInt(reduceFee) *
                  parseInt(AvgSlidingFee) *
                  (52 - parseInt(vacationWeek))) /
                12
              }
              boldParaText="Pro-bono and Reduced Fee Clients"
            />
            <BoldParaToolTipAndInput
              value={superTeaching}
              unit="$"
              inputOnchange={(e) => {
                setSuperTeaching(e.target.value);
              }}
              boldParaText="Supervision"
              tooltipText="If you provide supervision for a fee."
            />
            <BoldParaToolTipAndInput
              value={rentalIncome}
              unit="$"
              inputOnchange={(e) => {
                setRentalIncome(e.target.value);
              }}
              boldParaText="Rental Income"
              tooltipText="If you sublet your office or manage office rentals."
            />
            <BoldParaToolTipAndInput
              value={leaderShip}
              unit="$"
              inputOnchange={(e) => {
                setleaderShip(e.target.value);
              }}
              boldParaText="Thought Leadership"
              tooltipText="If you teach, give paid presentations, sell products."
            />
            <BoldParaToolTipAndInput
              value={mics}
              unit="$"
              inputOnchange={(e) => {
                setMics(e.target.value);
              }}
              boldParaText="Miscellaneous"
              tooltipText="List additional sources of income."
            />
            <BlackandGreen
              proBoneReducedFee={
                (parseInt(reduceFee) *
                  parseInt(AvgSlidingFee) *
                  (52 - parseInt(vacationWeek))) /
                12
              }
              superTeaching={superTeaching}
              rentalIncome={rentalIncome}
              leaderShip={leaderShip}
              mics={mics}
              text="Total Income"
            />
            {console.log(proBoneReducedFee + "kkk")}
          </div>
          <div className="main_row2_c2">
            <Heading text="Tax + Estate Planning " />
            <Paragraph text="Let us know your unique tax situation" />
            <BoldParaTTipIRange
              currentValue={tax}
              max={100}
              unit="%"
              onchange={(e) => {
                if (e.target.value > 100) {
                  return;
                }
                setTax(e.target.value);
              }}
              boldParaText="What percentage of your income would you like to set aside for taxes?"
              tooltipText="Small businesses and sole proprietors are required to
              pay Quarterly Estimates. Generally, it’s recommended to set aside between 30-50% of your net income."
            />
            <BoldParaTTipIRange
              currentValue={estate}
              max={100}
              unit="%"
              onchange={(e) => {
                if (e.target.value > 100) {
                  return;
                }
                setEstate(e.target.value);
              }}
              boldParaText="What percentage of your income would you like to set aside for estate planning?"
              tooltipText="This includes retirement accounts such as 401ks or
              SEP-IRAs, brokerage accounts, investments, etc."
            />
          </div>
        </div>
        <div className="main_row3">
          <div className="main_row31">
            <BsCheck2Circle className="main_icon" size="40" color="61D6A4" />
            <Heading className="main-heading" text="Business Expenses" />
          </div>
          <BoldParaToolTipAndInput
            unit="$"
            inputOnchange={(e) => {
              setMonthlyExpences(e.target.value);
            }}
            boldParaText="What are your total monthly expenses?"
            tooltipText="Use the Clarity Cooperative Business Expenses Calculator to determine an approximated value, or input a rough estimate."
          />
          <Paragraph text="See Business Expenses and Tax Deductions Calculator here." />
        </div>
        <div className="main_row4">
          <BlackandGreenTax
            value={
              (parseInt(tax) / 100) * parseInt(AnnualIncome) +
              (parseInt(estate) / 100) * parseInt(AnnualIncome)
            }
            text="Total Income"
          />
        </div>
        <ColoredLine />
        {/* <BoldParaTTipIRange boldParaText="bolasdfasdfasdfadsfasf" tooltipText="asfasdfdasddfasfaf"/>
      <BoldParaAndToolTip  boldParaText="thi is bold para text" tooltipText="this is tool tip text" />
      <BoldParaToolTipAndInput boldParaText="this is a bold para" tooltipText="thi is a tool tip text"/> */}
        <CalculationSummary
          sessionHour={WeekSessions}
          workweek={52 - parseInt(vacationWeek)}
          AnnualExpense={parseInt(monthlyExpences) * 12}
          AnnualIncome={AnnualIncome}
          taxGrossIncome={
            parseInt(AnnualIncome) +
            ((parseInt(tax) / 100) * parseInt(AnnualIncome) +
              (parseInt(estate) / 100) * parseInt(AnnualIncome))
          }
        />
      </div>
    </>
  );
};
