import React, { memo } from "react";
import { Tooltip } from "../tooltip/Tooltip";
import "./boldParagraph.css";
export const BoldParagraph = memo(({ text, Tooltiptext = "" }) => {
  return (
    <div className="boldParagraph">
      {text} <Tooltip Tooltiptext={Tooltiptext} />
    </div>
  );
});
