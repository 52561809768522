import React from 'react'
import { GrCircleQuestion } from "react-icons/gr";
import "./tooltip.css"
export const Tooltip = ({Tooltiptext}) => {
  return (
      <>
<div className="tooltip">
    <GrCircleQuestion size={20}/>
  <span className="tooltiptext">{Tooltiptext}</span>
</div> </> )
}
