import React from "react";
import "./style.css";
// import bg from "bggreen.svg";

function CalculatorHeader({ title, subtitle, text }) {
  return (
    <div className="calculatorHeader">
      <div className="calculatorHeader__content">
        <h1>{title}</h1>
        <h3>{subtitle}</h3>
        <p>{text}</p>
      </div>
      <img
        src={process.env.PUBLIC_URL + "/bggreen.svg"}
        className="calculatorHeader__bgimage"
      />
      <img
        src={process.env.PUBLIC_URL + "/Vector.svg"}
        className="calculatorHeader__vector"
      />
    </div>
  );
}

export default CalculatorHeader;
