import React, { memo } from "react";
import { BoldParagraph } from "../boldParagraph/BoldParagraph";
import { Tooltip } from "../tooltip/Tooltip";
import "./boldParaAndToolTip.css";
export const BoldParaAndToolTip = memo(({ boldParaText, tooltipText = "" }) => {
  return (
    <div className="boldParaAndToolTip_container">
      <div className="child1">
        <BoldParagraph text={boldParaText} Tooltiptext={tooltipText} />
      </div>
    </div>
  );
});
